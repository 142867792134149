import { render, staticRenderFns } from "./processOrderDetails.vue?vue&type=template&id=94041110&scoped=true&"
import script from "./processOrderDetails.vue?vue&type=script&lang=js&"
export * from "./processOrderDetails.vue?vue&type=script&lang=js&"
import style0 from "./processOrderDetails.vue?vue&type=style&index=0&id=94041110&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94041110",
  null
  
)

export default component.exports