var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-page-box"},[(_vm.detailObj.state !== '09')?_c('div',{staticClass:"step-box"},_vm._l((_vm.step.currentStepArr),function(item,index){return _c('div',{key:index,staticClass:"item",class:_vm.step.activeIndex > index
          ? 'active'
          : _vm.step.activeIndex === index
            ? 'active-equal'
            : ''},[_c('div',{staticClass:"num-box"},[(index > _vm.step.activeIndex)?_c('span',{staticClass:"num"},[_vm._v(_vm._s(index + 1))]):(index === _vm.step.activeIndex)?_c('span',{staticClass:"num"},[_vm._v(_vm._s(index + 1))]):_c('span',{staticClass:"num"},[_c('i',{staticClass:"el-icon-check"})]),(index === 5&&_vm.detailObj.replayDeliveryTime)?_c('span',{staticClass:"delivery-date"},[_vm._v("交期："+_vm._s(_vm.detailObj.replayDeliveryTime))]):_vm._e()]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(index === 3 || index === 5 ? item.agentPerson : item.userName))]),_c('span',{staticClass:"timer"},[_vm._v(_vm._s(item.operateTime))]),(_vm.step.activeIndex > index)?_c('div',{staticClass:"pop-up-box"},_vm._l((item.children),function(nape,num){return _c('div',{key:num,staticClass:"pay-box"},[_c('span',[_vm._v(_vm._s(nape.label))]),_c('span',[_vm._v(_vm._s(item[nape.value]))])])}),0):_vm._e()]),_c('span',{staticClass:"line"})])}),0):_c('div',{staticClass:"step-box cancel-box"},_vm._l((_vm.detailObj?.factoryOrderNodeList),function(item,index){return _c('div',{key:index,staticClass:"item",class:_vm.step.activeIndex > index
          ? 'active'
          : _vm.step.activeIndex === index
            ? 'active-equal'
            : ''},[_c('div',{staticClass:"num-box"},[(index > _vm.step.activeIndex)?_c('span',{staticClass:"num"},[_vm._v(_vm._s(index + 1))]):(index === _vm.step.activeIndex)?_c('span',{staticClass:"num active"},[_vm._v(_vm._s(index + 1))]):(_vm.detailObj?.factoryOrderNodeList?.length === index + 1)?_c('span',{staticClass:"num red-box"},[_c('i',{staticClass:"el-icon-close"})]):_c('span',{staticClass:"num"},[_c('i',{staticClass:"el-icon-check"})])]),_c('div',{staticClass:"name"},[(_vm.detailObj?.factoryOrderNodeList?.length === index + 1)?_c('span',{staticClass:"color-font"},[_vm._v(_vm._s(item.name))]):_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"user-name"},[_vm._v(_vm._s(item.userName))]),_c('span',{staticClass:"timer"},[_vm._v(_vm._s(item.operateTime))]),(_vm.step.activeIndex > index)?_c('div',{staticClass:"pop-up-box"},_vm._l((item.children),function(nape,num){return _c('div',{key:num,staticClass:"pay-box"},[_c('span',[_vm._v(_vm._s(nape.label))]),_c('span',[_vm._v(_vm._s(item[nape.value]))])])}),0):_vm._e(),(_vm.detailObj?.factoryOrderNodeList?.length === index + 1)?_c('div',{staticClass:"pop-up-box"},[_c('div',{staticClass:"pay-box"},[_c('span',[_vm._v("取消人")]),_c('span',[_vm._v(_vm._s(item.userName))])]),_c('div',{staticClass:"pay-box"},[_c('span',[_vm._v("操作时间")]),_c('span',[_vm._v(_vm._s(item.operateTime))])]),_c('div',{staticClass:"pay-box"},[(item.cancelReason === '01')?_c('span',[_vm._v("买家自行取消")]):(item.cancelReason === '02')?_c('span',[_vm._v("卖家自行取消")]):(item.cancelReason === '03')?_c('span',[_vm._v("超期未支付自动取消")]):_vm._e()])]):_vm._e()]),_c('span',{staticClass:"line"})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }