<template>
  <div class="step-page-box">
    <!-- 这个是正常情况下的  08为已取消的  显示下方 -->
    <div v-if="detailObj.state !== '09'" class="step-box">
      <div
        v-for="(item, index) in step.currentStepArr"
        :key="index"
        class="item"
        :class="
          step.activeIndex > index
            ? 'active'
            : step.activeIndex === index
              ? 'active-equal'
              : ''
        "
      >
        <!-- 这个是数字序号 -->
        <div class="num-box">
          <span v-if="index > step.activeIndex" class="num">{{
            index + 1
          }}</span>
          <span v-else-if="index === step.activeIndex" class="num">{{
            index + 1
          }}</span>
          <span v-else class="num">
            <i class="el-icon-check" />
          </span>
          <span
            v-if="index === 5&&detailObj.replayDeliveryTime"
            class="delivery-date"
          >交期：{{ detailObj.replayDeliveryTime }}</span>
        </div>
        <div class="name">
          {{ item.name }}
          <span class="user-name">{{ index === 3 || index === 5 ? item.agentPerson : item.userName }}</span>
          <span class="timer">{{ item.operateTime }}</span>
          <!-- 这个是支付的状态的窗口 -->
          <div v-if="step.activeIndex > index" class="pop-up-box">
            <div
              v-for="(nape, num) in item.children"
              :key="num"
              class="pay-box"
            >
              <span>{{ nape.label }}</span>
              <span>{{ item[nape.value] }}</span>
            </div>
          </div>
        </div>
        <span class="line" />
      </div>
    </div>
    <!-- 这个是取消情况下的  就只有两个或者三个步骤了 -->
    <div v-else class="step-box cancel-box">
      <div
        v-for="(item, index) in detailObj?.factoryOrderNodeList"
        :key="index"
        class="item"
        :class="
          step.activeIndex > index
            ? 'active'
            : step.activeIndex === index
              ? 'active-equal'
              : ''
        "
      >
        <!-- 这个是数字序号 -->
        <div class="num-box">
          <span v-if="index > step.activeIndex" class="num">{{
            index + 1
          }}</span>
          <span v-else-if="index === step.activeIndex" class="num active">{{
            index + 1
          }}</span>
          <span
            v-else-if="detailObj?.factoryOrderNodeList?.length === index + 1"
            class="num red-box"
          >
            <i class="el-icon-close" />
          </span>
          <span v-else class="num">
            <i class="el-icon-check" />
          </span>
        </div>
        <div class="name">
          <!-- 如果是已取消就换种颜色 -->
          <span
            v-if="detailObj?.factoryOrderNodeList?.length === index + 1"
            class="color-font"
          >{{ item.name }}</span>
          <span v-else>{{ item.name }}</span>
          <span class="user-name">{{ item.userName }}</span>
          <span class="timer">{{ item.operateTime }}</span>
          <div v-if="step.activeIndex > index" class="pop-up-box">
            <div
              v-for="(nape, num) in item.children"
              :key="num"
              class="pay-box"
            >
              <span>{{ nape.label }}</span>
              <span>{{ item[nape.value] }}</span>
            </div>
          </div>
          <!-- 这个是已取消的弹窗 -->
          <div
            v-if="detailObj?.factoryOrderNodeList?.length === index + 1"
            class="pop-up-box"
          >
            <div class="pay-box">
              <span>取消人</span>
              <span>{{ item.userName }}</span>
            </div>
            <div class="pay-box">
              <span>操作时间</span>
              <span>{{ item.operateTime }}</span>
            </div>
            <div class="pay-box">
              <span v-if="item.cancelReason === '01'">买家自行取消</span>
              <span v-else-if="item.cancelReason === '02'">卖家自行取消</span>
              <span
                v-else-if="item.cancelReason === '03'"
              >超期未支付自动取消</span>
            </div>
          </div>
        </div>
        <span class="line" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailObj: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      step: {
        activeIndex: 0,
        stepArr: [
          {
            name: '下单',
            state: '01',
            children: [
              { label: '下单人', value: 'userName' },
              { label: '时间', value: 'operateTime' }
            ]
          },
          {
            name: '报价',
            state: '02',
            children: [
              { label: '报价人', value: 'userName' },
              { label: '交期', value: 'issueDate' },
              { label: '总金额', value: 'amount' },
              { label: '时间', value: 'operateTime' }
            ]
          },
          {
            name: '支付',
            state: '03',
            children: [
              { label: '支付人', value: 'userName' },
              { label: '支付金额', value: 'amount' },
              { label: '时间', value: 'operateTime' }
            ]
          },
          {
            name: '发料',
            state: '04',
            children: [
              { label: '经办人', value: 'agentPerson' },
              { label: '发料日期', value: 'issueDate' },
              { label: '运输司机', value: 'transportDriver' },
              { label: '运输车牌号', value: 'transportCarCode' },
              { label: '时间', value: 'operateTime' }
            ]
          },
          {
            name: '收料',
            state: '05',
            children: [
              { label: '收料人', value: 'userName' },
              { label: '时间', value: 'operateTime' }
            ]
          },
          {
            name: '发货',
            state: '06',
            children: [
              { label: '经办人', value: 'agentPerson' },
              { label: '发货日期', value: 'issueDate' },
              { label: '运输司机', value: 'transportDriver' },
              { label: '运输车牌号', value: 'transportCarCode' },
              { label: '时间', value: 'operateTime' }
            ]
          },
          {
            name: '收货',
            state: '07',
            children: [
              { label: '收货人', value: 'userName' },
              { label: '时间', value: 'operateTime' },
              { label: '', value: 'receiveReason' }
            ]
          }
        ],
        currentStepArr: []
      }
    }
  },
  watch: {
    detailObj: {
      handler(newVal) {
        if (newVal.id) {
          // 如果是已取消的  就只有三个 数据就要倒推拿取了
          if (newVal.state === '09') {
            this.step.activeIndex = newVal?.factoryOrderNodeList?.length || 0
        newVal?.factoryOrderNodeList.forEach((item, index) => {
          const obj = this.step.stepArr.find((val) => val.state === item.nodeName)
          if (obj?.name) {
            item.name = obj.name
            item.children = [...obj.children]
          }
          if (newVal?.factoryOrderNodeList?.length === index + 1) {
            item.name = '取消'
          }
        })
        console.log(newVal?.factoryOrderNodeList)
        return
          }
          // 取已经走过的流程   然后去进行加上去节点   这个是没有已取消的  正常走下去的
          this.step.stepArr.forEach((item) => {
            const obj = newVal?.factoryOrderNodeList.find(
              (val) => val.nodeName === item.state
            )
            this.step.currentStepArr.push({
              ...item,
              ...obj
            })
          })
          // 根据节点数量去判断进行到第几步了
          this.step.activeIndex = newVal?.factoryOrderNodeList?.length || 0
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.step-page-box {
  .step-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 146px;
    // overflow: hidden;
    .item {
      display: flex;
      align-items: center;
      font-size: 16px;
      flex: auto;
      color: #999999;
      .num-box {
        position: relative;
        .delivery-date {
          position: absolute;
          top: -32px;
          left: -44px;
          width: 114px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          background-color: #f89705;
          color: #fff;
          font-size: 12px;
          border-radius: 2px;
        }
        .delivery-date::after {
          content: "";
          position: absolute;
          top: 22px;
          left: 50%;
          transform: translateX(-50%);
          border: 6px solid #f89705;
          border-bottom: 6px solid transparent;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }
      .num {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border: 1px solid #999999;
        border-radius: 50%;
        margin-right: 16px;
      }
      .red-box {
        border: 1px solid #d31e0e !important;
        background-color: #d31e0e !important;
      }
      .line {
        margin: 0 16px;
        flex: 1;
        height: 2px;
        background-color: #dcdcdc;
      }
      .name {
        position: relative;
        font-size: 14px;
        min-width: 2px;
        .color-font {
          color: #d31e0e;
        }
        .pop-up-box {
          position: absolute;
          z-index: 1;
          top: 26px;
          left: 0;
          width: 203px;
          // height: 145px;
          display: none;
          background-color: #fff;
          font-size: 12px;
          border-radius: 2px;
          box-shadow: 0 -7px 30px 0 rgba(0, 0, 0, 0.12),
            0 8px 24px 0 rgba(0, 0, 0, 0.12), 0 4px 10px 0 rgba(0, 0, 0, 0.12);
          .pay-box {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 12px;
            span {
              color: #666;
            }
            span:last-of-type {
              color: #000;
            }
          }

          .check-bill {
            display: block;
            padding-top: 12px;
            margin: 18px 12px;
            color: #0172eb;
            text-align: center;
            border-top: 1px solid #ededed;
          }
        }
        .pop-up-box::after {
          content: "";
          position: absolute;
          top: -12px;
          left: 8px;
          border-bottom: 6px solid #fff;
          border-top: 6px solid transparent;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }
        .user-name {
          position: absolute;
          top: 22px;
          left: 0;
          color: #666666;
          width: 500%;
        }
        .timer {
          position: absolute;
          top: 44px;
          left: 0;
          color: #999999;
          width: 500%;
        }
      }
      .name:hover {
        cursor: pointer;
        .pop-up-box {
          display: inline-block;
        }
      }
    }
    .active {
      color: #333;
      .num {
        border: 1px solid #0172eb;
        background-color: #0172eb;
        color: #fff;
      }
      .line {
        background-color: #0172eb;
      }
    }
    .active-equal {
      color: #0172eb;
      .num {
        border: 1px solid #0172eb;
        background-color: #0172eb;
        color: #fff;
      }
    }
    .item:last-of-type {
      flex: 0 0 178px;
      .line {
        display: none;
      }
      .pop-up-box {
        left: -50px;
      }
      .pop-up-box::after {
        left: 58px;
      }
    }
  }
  .cancel-box {
    justify-content: flex-start;
    .item {
      flex: 0 0 230px;
    }
  }
}
</style>
